var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a-form',{attrs:{"form":_vm.groupForm,"layout":"horizontal","label-col":_vm.formItemLayout.labelCol,"autocomplete":"nope"}},[_c('a-form-item',{attrs:{"label":_vm.$t('house.name'),"wrapperCol":_vm.formItemLayout.wrapperCol}},[_c('a-input',{directives:[{name:"decorator",rawName:"v-decorator",value:([
        'groupName-' + _vm.index,
        {
          rules: [
            {
              required: true,
              message: _vm.$t('house.enterGroupName'),
            },
            { max: 50, message: _vm.$t('house.textLimit50') } ],
        } ]),expression:"[\n        'groupName-' + index,\n        {\n          rules: [\n            {\n              required: true,\n              message: $t('house.enterGroupName'),\n            },\n            { max: 50, message: $t('house.textLimit50') },\n          ],\n        },\n      ]"}],attrs:{"addonBefore":_vm.houseName}})],1),_c('a-form-item',{attrs:{"label":_vm.$t('house.tutor'),"wrapperCol":_vm.formItemLayout.wrapperCol}},[(_vm.selectedTutors.length)?_c('div',{staticClass:"tag-container"},_vm._l((_vm.selectedTutors),function(item){return _c('a-tag',{key:item.id,attrs:{"visible":true,"closable":""},on:{"close":function($event){return _vm.removeSelectedTutor(item.id)}}},[_vm._v(_vm._s(item.title))])}),1):_vm._e(),_c('a-button',{attrs:{"block":"","type":"dashed","icon":"plus"},on:{"click":function($event){return _vm.addPerson('tutor')}}},[_vm._v(_vm._s(_vm.$t('house.addTeacher')))])],1),_c('a-form-item',{attrs:{"label":_vm.$t('house.student'),"wrapperCol":_vm.formItemLayout.wrapperCol}},[(_vm.selectedStudents.length)?_c('div',{staticClass:"tag-container"},_vm._l((_vm.selectedStudents),function(item){return _c('a-tag',{key:item.id,attrs:{"visible":true,"closable":""},on:{"close":function($event){return _vm.removeSelectedStudent(item.id)}}},[_vm._v(_vm._s(item.title))])}),1):_vm._e(),_c('a-button',{attrs:{"block":"","type":"dashed","icon":"plus"},on:{"click":function($event){return _vm.addPerson('student')}}},[_vm._v(_vm._s(_vm.$t('house.addStudent')))])],1),_c('group-transfer',{attrs:{"title":_vm.transferTitle,"defaultCandidateData":_vm.transferCandidate,"defaultSelectedData":_vm.transferSelected},on:{"confirm":_vm.getSelectedData},model:{value:(_vm.transferVisible),callback:function ($$v) {_vm.transferVisible=$$v},expression:"transferVisible"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }